<template>
  <div class="home">
    <div class="container mx-auto">
      <div class="flex flex-row flex-wrap content-start ">
        <BookCardComponent v-for="book in books" :key="book.id" class="mx-4 my-4" :book="book" /> 
      </div>
    </div>
  </div>
</template>

<script>
import BookCardComponent from '@/components/BookCardComponent'
import { mapState } from 'vuex'

export default {
  name: 'home',
  components: {
    BookCardComponent
  },

  computed: {
    ...mapState(['books'])
  },

  mounted() {

  }
}
</script>

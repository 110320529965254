<template>
  <header class="text-white shadow-md mb-16">
      <div class="container mx-auto">
        <div class="flex items-center content-around justify-between h-16">
          <router-link :to="{name: 'home'}">
          <h1 class="text-4xl font-sans font-bold">كتب</h1> 
          </router-link>
          <div class="flex items-center">
              <router-link class="ml-6 font-sans" to="/">الرئيسية</router-link> 
              <router-link class="ml-6 font-sans" to="/about">عنا</router-link>
              <i class="icon ion-md-search"></i>
          </div>
        </div>
      </div>
  </header>
</template>

<script>

export default {
    name: 'HeaderComponent',
}
</script>

<style lang="scss" scoped>
header {
  background-color: #01b075;
}
</style>
<template>
  <div class="about">
    <h1>عن موقع كتب</h1>
  </div>
</template>
<script>
export default {
  name: "about"
}
</script>

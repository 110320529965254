import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    books: [
      {
        id: 12,
        title: 'مع الله',
        price: 102,
        price_compare_at: null,
        image: 'book/images/01.png',
        description: '......',
      },
      {
        id: 1,
        title: 'السر في تداول النجاحالسر في تداول النجاحالسر في تداول النجاحالسر في تداول النجاحالسر في تداول النجاحالسر في تداول النجاحالسر في تداول النجاحالسر في تداول النجاحالسر في تداول النجاح',
        price: 102,
        price_compare_at: null,
        image: 'https://s3-eu-central-1.amazonaws.com/salla-cdn/zEtgb6tLkRv9ktsMtUDfCDLw9tSQxEBvWTeZlvyT.png',
        description: 'هذا الكتاب لا يتطلب من القارئ أن يكون ضليعًا في التداول، أو أن يكون خبيرًا في علوم الاقتصاد، بل على العكس تمامًا، هذا الكتاب مُوَجَّهٌ بالدرجة الأولى لمن لا يملك أي خبرة في التداول، ويرغب في أن يكون متداولً محترفًا ناجحًا. ولمن لديه الخبرة ولكن اختلطت عليه المفاهيم وتعددت أمامه المعلومات، فهذا الكتاب سيكون دليلًا إرشاديًّا له يساعده على إيجاد الطريق الصحيح لتحقيق أهدافه من التداول وتحقيق أرباح مستدامة وثابتة وفق خطط واستراتيجيات ثابتة وواضحة.        ',
      },      
      {
        id: 2,
        title: 'السر في تداول النجاح',
        price: 102,
        price_compare_at: null,
        image: 'https://s3-eu-central-1.amazonaws.com/salla-cdn/zEtgb6tLkRv9ktsMtUDfCDLw9tSQxEBvWTeZlvyT.png',
        description: 'هذا الكتاب لا يتطلب من القارئ أن يكون ضليعًا في التداول، أو أن يكون خبيرًا في علوم الاقتصاد، بل على العكس تمامًا، هذا الكتاب مُوَجَّهٌ بالدرجة الأولى لمن لا يملك أي خبرة في التداول، ويرغب في أن يكون متداولً محترفًا ناجحًا. ولمن لديه الخبرة ولكن اختلطت عليه المفاهيم وتعددت أمامه المعلومات، فهذا الكتاب سيكون دليلًا إرشاديًّا له يساعده على إيجاد الطريق الصحيح لتحقيق أهدافه من التداول وتحقيق أرباح مستدامة وثابتة وفق خطط واستراتيجيات ثابتة وواضحة.        ',
      },  
      {
        id: 3,
        title: 'السر في تداول النجاح',
        price: 222,
        price_compare_at: null,
        image: 'https://s3-eu-central-1.amazonaws.com/salla-cdn/zEtgb6tLkRv9ktsMtUDfCDLw9tSQxEBvWTeZlvyT.png',
        description: 'هذا الكتاب لا يتطلب من القارئ أن يكون ضليعًا في التداول، أو أن يكون خبيرًا في علوم الاقتصاد، بل على العكس تمامًا، هذا الكتاب مُوَجَّهٌ بالدرجة الأولى لمن لا يملك أي خبرة في التداول، ويرغب في أن يكون متداولً محترفًا ناجحًا. ولمن لديه الخبرة ولكن اختلطت عليه المفاهيم وتعددت أمامه المعلومات، فهذا الكتاب سيكون دليلًا إرشاديًّا له يساعده على إيجاد الطريق الصحيح لتحقيق أهدافه من التداول وتحقيق أرباح مستدامة وثابتة وفق خطط واستراتيجيات ثابتة وواضحة.        ',
      },  
      {
        id: 33,
        title: 'السر في تداول النجاح',
        price: 222,
        price_compare_at: null,
        image: 'https://s3-eu-central-1.amazonaws.com/salla-cdn/zEtgb6tLkRv9ktsMtUDfCDLw9tSQxEBvWTeZlvyT.png',
        description: 'هذا الكتاب لا يتطلب من القارئ أن يكون ضليعًا في التداول، أو أن يكون خبيرًا في علوم الاقتصاد، بل على العكس تمامًا، هذا الكتاب مُوَجَّهٌ بالدرجة الأولى لمن لا يملك أي خبرة في التداول، ويرغب في أن يكون متداولً محترفًا ناجحًا. ولمن لديه الخبرة ولكن اختلطت عليه المفاهيم وتعددت أمامه المعلومات، فهذا الكتاب سيكون دليلًا إرشاديًّا له يساعده على إيجاد الطريق الصحيح لتحقيق أهدافه من التداول وتحقيق أرباح مستدامة وثابتة وفق خطط واستراتيجيات ثابتة وواضحة.        ',
      },  
    ]
  },
  
  getters: {
    books: state => state.books,
    bookById: (state) => (id) => {
      return state.books.find(book => book.id === id)
    }
  },

  mutations: {
  },

  actions: {
    getAllBooks() {
      return new Promise(() => {

      })
    }
  },

  modules: {
  }
})

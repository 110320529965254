<template>
<div class="book-card bg-white border rounded-lg overflow-hidden">
    <router-link :to="{name: 'book', params: {id: book.id } }">
    <div class="book-img">
        <img class="object-cover w-full h-full" loading=lazy 
        :src="book.image" alt="">
    </div>
    <div class="p-4 text-right">
        <h3 class="text-xl font-sans truncate" :title="book.title">{{ book.title }}</h3>
        <div class="font-semibold text-xl mt-2 text-gray-700"><span>{{ book.price }} </span>
            <span>درهم</span>
        </div>
    </div>
    </router-link>
</div>
</template>

<script>
export default {
    name: 'BookCardComponent',
    props: [
        'book'
    ]
}
</script>

<style scoped>
.book-card {
    width: 280px;
}

.book-img {
    background: #ddd;
    height: 380px;
    width: 280px;
}
</style>
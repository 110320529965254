<template>
  <div class="container mx-auto bg-white" v-if="book">
    <div class="flex justify-start">
      <div class="w-1/3">
        <img
          :src="book.image"
        />
      </div>
      <div class="w-2/3 p-4 text-right">
        <h1 class="text-right font-semibold font-sans text-2xl mb-5">{{ book.title }}</h1>
        <p class="text-justify text-xl" >
            {{ book.description }}
        </p>

        <div class="font-semibold text-2xl mt-2 text-gray-700 mb-5">
          {{ book.price }}
          <span>درهم</span>
        </div>

        <div>
          <button
            class="bg-green-500 hover:bg-green-700 text-white font-semibold py-4 px-8 rounded font-sans ml-4"
          >
            <i class="icon ion-logo-whatsapp text-2xl"></i>
            <span> شراء عبر الواتساب</span>
          </button>

          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-4 px-8 rounded font-sans"
          >
            <i class="icon ion-md-send text-2xl"></i>
            <span> شراء عبر تيلقرام</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "Book",
  
  data: function () {
      return {
          id: null,
          book: null
      }
  },

  computed: {
      ...mapGetters(['bookById'])
  },

  mounted() {
    this.id = this.$route.params.id      
    this.book = this.bookById(this.id)
  }
};
</script>

<style>
</style>